.App {
  text-align: center;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  overflow-x: hidden;
}

button{
  cursor: pointer;
}
