.App {
    text-align: center;
}

.App-header {
    background-color: #fff;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
}

.aboutUsPage-section {
    display: flex;
    color: #000000;
    min-height: 500px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 500px;
}

.aboutUsPage-container {
    margin: auto;
    width: 100%;
}

.aboutUsPage-content1 {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    min-height: 500px;
    background: linear-gradient(180deg, #034A87 10.32%, #481C69 82.09%);
}

.aboutUsPage-content1 .left-column {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-left: 8%;
    height: 50vh;
}

.aboutUsPage-content1 .left-column .heading {
    width: 100%;
}

.aboutUsPage-content1 .left-column .content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
}

.aboutUsPage-content1 .left-column h1{
    font-size: 6.2vw;
    color: white;
    font-weight: 1000;
    font-family: 'Josefin Sans';
    width: 100%;
    text-align: start;
    margin: 0;
}

.aboutUsPage-content1 .left-column p {
    color: white;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 164%;
    text-align: start;
    width: 95%;
    margin: 0;
}

.aboutUsPage-content1 .left-column #one {
    margin: 3vw 0vw 2vw 0vw;
}

.aboutUsPage-content1 .left-column #two {
    margin-bottom: 2vw;
}



.aboutUsPage-content1 .right-column {
    width: 90%;
    height: 82vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.aboutUsPage-Navbar {
    display: flex;
    align-items: center;
    max-width: 100%;
    justify-content: space-between;
    padding: 0vw 8vw 0vw 8vw;
    background-color: white;
}

.aboutUsPage-Navbar .logo {
    padding-left: 0vw;
}

.aboutUsPage-content2 {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: space-evenly;
}

.aboutUsPage-content2 .top-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aboutUsPage-content2 .top-side h1 {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 3.5vw;
    line-height: 124.5%;
    color: #3D3B3B;
}

.aboutUsPage-content2 .top-side p {
    margin: 0;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 138.5%;
    text-align: center;
    color: #3D3B3B;
    width: 73%;
}

.aboutUsPage-content2 .bot-side {
    display: flex;
    width: 90%;
}

.aboutUsPage-content2 .bot-side .sections {
    display: flex;
    height: 50vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.aboutUsPage-content2 .bot-side .sections h1 {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.3vw;
    line-height: 124.5%;
    margin: 0;
    margin-top: 1vw;
    color: #3D3B3B;
}

.aboutUsPage-content2 .bot-side .sections .icons{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 8vw;
}


.aboutUsPage-content2 .bot-side .sections p {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: .9vw;
    line-height: 138.5%;

    text-align: center;
    
    color: #3D3B3B;

    margin-top: 1.5vw;

    width: 65%;
}

.aboutUsPage-content2 .bot-side .sections svg{
    width: 7.5vw;
    height: 6vw;
}

.aboutUsPage-content1 .right-column img {
    width: 41vw;
}

.aboutUsPage-container2{
    margin: auto;
    width: 100%;
}

@media (max-width: 850px) {
    .aboutUsPage-section {
        align-items: center;
        background-color: #fff;
        color: #000;
        display: flex;
        justify-content: center;
        min-height: 420px;
        width: 100%;
    }

    .aboutUsPage-container {
        width: 100%;
        justify-content: center;
        align-items: end;
        background: linear-gradient(180deg, #034a87 10.32%, #481c69 82.09%);
        display: flex;
        height: 100vh;
        margin: 0;
        min-height: 500px;
        padding: 0;
    }

    .aboutUsPage-content1 {
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 92vh;
        justify-content: space-evenly;
        width: 100vw;
    }

    .aboutUsPage-content1 .left-column {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 40%;
        justify-content: space-evenly;
        padding: 0;
        width: 100%;
    }

    .aboutUsPage-content1 .left-column .heading {
        width: 84%;
    }

    .aboutUsPage-content1 .left-column h1 {
        color: #fff;
        font-family: Josefin Sans;
        font-size: 3em;
        font-weight: 1000;
        margin: 0;
        text-align: start;
        width: 100%;
    }

    .aboutUsPage-content1 .left-column .content {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 84%;
    }

    .aboutUsPage-content1 .left-column #one {
        margin: 0vw 0 3vw;
    }

    .aboutUsPage-content1 .left-column p {
        color: #fff;
        font-family: Josefin Sans;
        font-size: .7em;
        font-style: normal;
        line-height: 164%;
        text-align: start;
    }

    .aboutUsPage-content1 .left-column #two {
        margin-bottom: 3vw;
    }

    .aboutUsPage-content1 .right-column {
        align-items: flex-end;
        display: flex;
        height: 45%;
        justify-content: center;
        width: 100%;
    }

    .aboutUsPage-content1 .right-column .figure{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .aboutUsPage-content1 .right-column img {
        width: 90vw;
        height: fit-content;
    }

    .aboutUsPage-container2{
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
    }

    .aboutUsPage-content2 {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        width: 100%;
        height: 274vw;
    }

    .aboutUsPage-content2 .top-side {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .aboutUsPage-content2 .top-side h1 {
        color: #3d3b3b;
        font-family: Josefin Sans;
        font-size: 5.5vw;
        font-style: normal;
        font-weight: 700;
        line-height: 124.5%;
        margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-top: 11vw;
        margin-bottom: 4vw;
    }

    .aboutUsPage-content2 .top-side p {
        color: #3d3b3b;
        font-family: Josefin Sans;
        font-size: 3.2vw;
        font-style: normal;
        font-weight: 400;
        line-height: 138.5%;
        margin: 0;
        text-align: center;
        width: 84%;
    }

    .aboutUsPage-content2 .bot-side {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 5vw;
        height: 100%;
    }

    .aboutUsPage-content2 .bot-side .sections {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 4vw 0vw;
        height: fit-content;
    }

    .aboutUsPage-content2 .bot-side .sections .icons {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .aboutUsPage-content2 .bot-side .sections svg {
        width: 19vw;
        height: fit-content;
    }

    .aboutUsPage-content2 .bot-side .sections h1 {
        color: #3d3b3b;
        font-family: Josefin Sans;
        font-size: .9em;
        font-style: normal;
        font-weight: 700;
        line-height: 124.5%;
        margin: 5vw 0 0;
    }

    .aboutUsPage-content2 .bot-side .sections p {
        color: #3d3b3b;
        font-family: Josefin Sans;
        font-size: .8em;
        font-style: normal;
        font-weight: 400;
        line-height: 138.5%;
        margin-top: 1.5vw;
        text-align: center;
        width: 65%;
    }

    .aboutUsPage-content2 .bot-side .sections .icons {
        height: 14vw;
    }
}
