.provide-section {
    align-items: center;
    background-color: #fff;
    color: #000;
    display: flex;
    height: 100vh;
    justify-content: center;
    min-height: 500px;
    width: 100%;
    overflow: hidden;
}

.provide-container {
    background: linear-gradient(180deg, #034a87 10.32%, #481c69 82.09%);
    display: flex;
    height: 100vh;
    margin: auto;
    width: 100%;
    min-height: 500px;
}

.provide-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 500px;
}


.provideHeading{
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 3.5vw;
    width: 70%;
    line-height: 124.5%;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.provide-icons {
    display: flex;
    justify-content: space-between;
    padding: 5vw 0px;
    width: 55vw;

}

.icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon {
    display: flex;
    width: 8vw;
    height: 8vw;
    background: white;
    border-radius: 130px;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vw;
}

.icon svg {
    width: 3vw;
    height: 3vw;
}

.icons h1 {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1vw;
    color: white;
    width: 8vw;
    line-height: 124.5%;
}

.provide-content button{
    height: 4vw;
    width: 13vw;
    background: white;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3vw;
    COLOR: black;
    font-family: 'Josefin Sans';
    position: relative;
    transition: all 400ms ease-in;
    top: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.provide-content button h1 {
    background: linear-gradient(90deg, #044986 2.52%, #481C69 86.55%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 1.2vw;
    letter-spacing: 0.05vw;
    font-weight: 600;

}


@media (max-width: 850px) {
    .provide-section {
        display: flex;
        min-height: 500px;
        width: 100%;
        height: 256vw;
        align-items: center;
        background-color: #fff;
        color: #000;
        justify-content: center;
        
    }

    .provide-container {
        display: flex;
        height: 100%;
        min-height: 500px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .provide-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 232vw;
        justify-content: space-evenly;
        min-height: 500px;
        position: relative;
        width: 100%;
    }

    .provideHeading {
        color: #fff;
        font-family: Josefin Sans;
        font-size: 7.5vw;
        font-style: normal;
        font-weight: 700;
        line-height: 100.5%;
        margin: 0;
        text-align: center;
        width: 88%;
    }

    .provide-icons {
        display: flex;
        flex-direction: column;
        height: 167vw;
        justify-content: space-between;
        width: 55vw;
    }

    .icon,
    .icons {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .icon {
        background: #fff;
        border-radius: 130px;
        height: 23vw;
        margin-bottom: 1vw;
        width: 23vw;
    }

    .icon svg {
        height: 10vw;
        width: 10vw;
    }

    .icons h1 {
        color: #fff;
        font-family: Josefin Sans;
        font-size: 3vw;
        font-style: normal;
        font-weight: 600;
        line-height: 124.5%;
        margin-top: 3vh;
        width: fit-content;
    }

    .provide-content button {
        COLOR: #000;
        align-items: center;
        background: #fff;
        border-radius: 8vw;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, .25);
        display: flex;
        font-family: Josefin Sans;
        height: 10vw;
        justify-content: center;
        transition: all .4s ease-in;
        width: 29vw;
        top: 0;
    }

    .provide-content button h1 {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, #044986 2.52%, #481c69 86.55%);
        background-clip: initial;
        -webkit-background-clip: text;
        background-clip: text;
        font-size: 3vw;
        font-weight: 200;
        letter-spacing: .05vw;
        margin: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: .5vw;
    }
}

