.Hero-section{
    position: relative;
    display: flex;
    color: #000000;
    min-height: 500px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.Hero-content{
    height: 105vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-top: 0vw;
    min-height: 500px;
}

.Hero-content .logo {
    position: absolute;
    top: 3%;
    left: 0%;
}

.Hero-content .logo a {
    position: relative;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: .8em;
    line-height: 128px;
    background-color: #000;

    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    margin-left: auto;

    text-decoration: none;
}

.Hero-section::before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-size: cover;
    background-image: url("../assets/heroBackground.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 5%;
}

.Hero-container {
    position: absolute;
    margin: auto 0 auto 40px;
    height: 100vh;
    width: 100%;
    padding: 0 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.Hero-container img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}

.Hero-content h1{
    position: relative;

    width: 80%;

    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 1000;
    font-size: 6.5vw;
    line-height: 8.5vw;

    text-align: center;

    background: linear-gradient(92.94deg, #064885 18.65%, #481C69 80.91%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}

.Hero-content h3 {
    position: relative;

    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.6vw;
    top: -4vw;


    color: #3D3B3B;

}

h5 {
    position: relative;
    
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 124.5%;
    cursor: pointer;
    padding: 0;
    margin:0;
    

    color: #000000;
}

.contact-us {

    height: 4vw;
    width: 13vw;
    background: linear-gradient(94.06deg, #034A87 10.32%, #481C69 82.09%);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3vw;
    COLOR: white;
    font-size: 1.4vw;
    font-family: 'Josefin Sans';
    top: -4vh;
    padding-top: .4vw;
    position: relative;
    transition: transform 400ms ease-in;
    border: none;

}

.contact-us:hover {
    transform: translateY(-.2vw);
}

.underline {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 8px;
    text-underline-position: from-font;
}

.you {
    text-decoration-color: #253381;
}

.matter::after {
    position: absolute;
    content: '';
    height: .03vw;
    bottom: 6px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(94.06deg, #034A87 10.32%, #481C69 82.09%);
}

.Hero-container .arrowSvg {
    height: 19vw;
    left: 60vw;
    position: absolute;
    top: 58.2vh;
    width: 19vw;
}

.headingSvg {
    height: 26vw;
    width: 58vw;
}

.fade-in {
    opacity: 0;
    animation-name: fade;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 850px) {


    .headingSvg{
        height: fit-content;
        width: 82vw;
        top: 9vw;
        position: relative;
    }

    .Hero-content h3 {
        color: #3d3b3b;
        font-size: 1em;
        font-weight: 700;
        width: 76%;
    }

    .Hero-content {
        flex-direction: column;
        height: 162vw;
        margin-top: 0;
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        min-height: 500px;
        position: relative;
        top: 7vw;
    }

    .Hero-content h3 {
        color: #3d3b3b;
        font-size: 4vw;
        font-weight: 700;
        top: 0;
        width: 76%;
    }

    .Hero-content button {
        COLOR: #fff;
        background: linear-gradient(94.06deg, #034a87 10.32%, #481c69 82.09%);
        border: none;
        border-radius: 7vw;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, .25);
        font-size: 3.5vw;
        font-weight: 200;
        height: 11.8vw;
        padding-top: .5vh;
        transition: -webkit-transform .4s ease-in;
        transition: transform .4s ease-in;
        transition: transform .4s ease-in, -webkit-transform .4s ease-in;
        width: 35vw;
        top: 0;
    }

    .contact-us:hover{
        transform: translateY(0vw);
    }

    .Hero-content h5{
        color: #000;
        cursor: pointer;
        font-size: .7em;
        font-style: normal;
        font-weight: 400;
        line-height: 124.5%;
        margin: 0;
        padding: 0;
    }

    .Hero-container .arrowSvg{
        height: 17vw;
        width: 17vw;
        transform: rotate(45deg);
        position: static;
    }

    .Hero-container {
        justify-content: center;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 100%;
        align-items: center;
        display: flex;
        height: 100%;
    }

    .Hero-section {
        align-items: center;
        display: flex;
        height: 100vh;
        justify-content: space-evenly;
        min-height: 500px;
        overflow: hidden;

    }
}

@media (min-width: 450) {
    .Hero-content {
        flex-direction: column;
        height: 151vw;
        margin-top: 0;
    }

    .headingSvg {
        height: fit-content;
        width: 78vw;
    }
}
