.extra-section {
    display: flex;
    color: #000000;
    min-height: 500px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.extra-container {
    height: 100vh;
    margin: auto;
    display: flex;
}

.extra-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: relative;
}

.extra-content .left-column {
    width: 50%;
    padding: 0vw 0vw 0vw 8vw;
}

.extra-content .left-column h1 {
    font-family: 'JOSEFIN SANS';
    font-size: 3.6vw;
    width: 37vw;
    text-align: left;
    color: #3D3B3B;
    line-height: 4.5vw;
}

.extra-content .left-column p {
    font-family: 'Josefin Sans';
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    width: 36vw;
    text-align: left;
    padding-bottom: 5vw;
    line-height: 2vw;
}

.extra-content .left-column button {
    height: 4vw;
    width: 13vw;
}

.extra-content .right-column{
    width: 75%;
}

.extra-content .right-column svg {
    width: 42vw;
    height: 68vh;
}
.button-container {
    display: flex;
    width: 100%;
}


@media (max-width: 850px) {
    .extra-section {

        align-items: center;
        background-color: #fff;
        color: #000;
        display: flex;
        height: fit-content;
        justify-content: center;
        margin-top: 11vw;
        min-height: 420px;
        width: 100%;
    }

    .extra-container {
        display: flex;
        height: 100%;
        margin: 0;
        min-height: 500px;
        padding: 0;
    }

    .extra-content {
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 196vw;
        justify-content: space-evenly;
        width: 100vw;
    }

    .extra-content .left-column {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 50%;
        justify-content: space-evenly;
        padding: 0;
        width: 100%;
    }

    .extra-content .left-column h1 {
        color: #3d3b3b;
        font-family: JOSEFIN SANS;
        font-size: 7.6vw;
        line-height: 9.5vw;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 86%;
    }

    .extra-content .left-column p {
        font-family: Josefin Sans;
        font-size: 3.7vw;
        font-style: normal;
        font-weight: 400;
        line-height: 6vw;
        padding-bottom: 5vw;
        margin: 0;
        text-align: left;
        width: 86vw;
    }

    .extra-content .left-column button {
        COLOR: #fff;
        background: linear-gradient(94.06deg, #034a87 10.32%, #481c69 82.09%);
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, .25);
        font-family: Josefin Sans;
        transition: all .4s ease-in;
        border-radius: 12vw;
        font-size: 3vw;
        height: 10vw;
        padding-top: .5vw;
        width: 29vw;
        top: 0;
    }

    .extra-content .right-column {
        width: 100%;
        height: 45%;
    }

    .extra-content .right-column .figure{
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 80vw;
    }

    .extra-content .right-column svg {
        height: 71vw;
        width: 90vw;
    }
}