.business-section {
    align-items: center;
    background-color: #fff;
    color: #000;
    display: flex;
    height: 100vh;
    justify-content: center;
    min-height: 500px;
    width: 100%;
}

.business-container {
    display: flex;
    height: 100vh;
    margin: auto;
}

.business-content {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: space-between;
    position: relative;
    width: 100vw;
}

.business-content .column-left  {
    height: 70vh;
    width: 48%;
    padding: 0vw 0vw 0vw 2.5vw;
}


.business-content .column-left .figure img {
    height: 40vw;
    width: 38vw;
}

.business-content .column-right {
    width: 43%;
    height: 65vh;
}

.business-content .column-right h1 {
    font-family: 'JOSEFIN SANS';
    font-size: 3.6vw;
    width: 40vw;
    text-align: left;
    color: #3D3B3B;
    line-height: 4.5vw;
}

.business-content .column-right p {
    font-family: 'Josefin Sans';
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    width: 36vw;
    text-align: left;
    padding-bottom: 5vw;
    line-height: 2vw;
}

.business-content .column-right .contact-us {
    height: 4vw;
    width: 13vw;
    background: linear-gradient(94.06deg, #034A87 10.32%, #481C69 82.09%);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3vw;
    COLOR: white;
    font-size: 1.4vw;
    font-family: 'Josefin Sans';
    top: -40px;
    position: relative;
    transition: all 400ms ease-in;
}

.button-container{
    width: 100%;
    display: flex;
}

@media (max-width: 850px) {
    .business-section {
        align-items: center;
        background-color: #fff;
        color: #000;
        display: flex;
        height: fit-content;
        justify-content: center;
        margin-top: 11vw;
        min-height: 420px;
        width: 100%;
    }

    .business-container {
        display: flex;
        height: 100%;
        margin: 0;
        min-height: 500px;
        padding: 0;
    }

    .business-content {
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column-reverse;
        height: 196vw;
        justify-content: space-evenly;
        width: 100vw;
    }

    .business-content .column-left {
        height: 50%;
        width: 100%;
    }

    .business-content .column-left .figure{
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    .business-content .column-left .figure img {
        height: 100%;
        width: 90vw;
        margin-top: 10vw;
    }

    .business-content .column-right {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 50%;
        justify-content: space-evenly;
        padding: 0;
        width: 100%;
    }

    .business-content .column-right h1 {
        color: #3d3b3b;
        font-family: JOSEFIN SANS;
        font-size: 7.6vw;
        line-height: 9.5vw;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 86%;
    }

    .business-content .column-right p {
        font-family: Josefin Sans;
        font-size: 3.7vw;
        font-style: normal;
        font-weight: 400;
        line-height: 6vw;
        padding-bottom: 5vw;
        margin: 0;
        text-align: left;
        width: 86vw;
    }

    .button-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .business-content .column-right .contact-us {
        COLOR: #fff;
        background: linear-gradient(94.06deg, #034a87 10.32%, #481c69 82.09%);
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, .25);
        font-family: Josefin Sans;
        transition: all .4s ease-in;
        border-radius: 12vw;
        font-size: 3vw;
        height: 10vw;
        padding-top: .5vw;
        width: 29vw;
        top: 0;
    }
}