nav {
    display: block;
}

.navbar {
    position: fixed;
    width: 100%;
    z-index: 999;
}

.navbar-width {
    align-items: center;
    background-color: #fff;
    box-shadow: 0 -5vw 7vw 0vw gray;
    display: flex;
    height: 8vh;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 8vw;
}

.navbar .logo{
    padding-top: .5vw;
}

.navbar .logo a {
    color: #000;
    font-family: Josefin Sans;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: 128px;
    margin-left: auto;
    text-align: center;
    text-decoration: none;

}

.navbar-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 2vw;
    height: 1.6vw;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    z-index: 999;
    position: absolute;
    top: .6vw;
    left: 16.55vw;
    transition: transform 500ms ease;
}

.navbar-toggle:hover {
    transform: translateY(-.2vw);
}

.hamburger {
    width: 100%;
    height: .3vw;
    background-color: #000;
    border-radius: 60px;
}

.x-show{
    align-items: center;
    background-color: initial;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0;
    z-index: 999;
    width: 12%;
    border-left: 1px solid black;
    position: initial;
}

.x-show:hover{
    background: linear-gradient(90deg, #034A87 0%, #481C69 40.09%);
    transform: translateY(0vw);
}

.navbar-hide {
    height: 0;
    opacity: 0;
}

.navbar-show {
    background: #fff;
    height: 100vh;
    left: 0;
    margin: 0;
    padding-top: 80px;
    position: fixed;
    text-align: center;
    top: 0;
    transition: height 2s ease;
    width: 100%;
}

.no-icon {
    display: none;
}

.inline-nav li {
    list-style: none;
    display: inline-block;
}

.navbar-hide li {
    border-bottom: 0px solid rgb(0, 0, 0);
}


.inline-nav {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    width: 83%;
    text-align: center;
    transition: all 0.3s ease;
    margin: 0;
}

.navbar-show li {
    list-style: none;
    display: block;
    width: 100%;
    text-align: start;
    height: 20%;
    border-bottom: 1px solid black;
    transition: border-bottom 1s ease;
    position: relative;
}


.navbar-show li a {
    display: block;
    color: #3D3B3B;
    font-size: 6vw;
    font-weight: 600;
    transition: color 0.3s ease;
    font-family: "Josefin Sans";
    letter-spacing: 2vw;
    padding: 0;
    position: absolute;
    top: 0;
    opacity: 1;
    transition: top 1s ease, opacity 500ms ease, letter-spacing 1.5s cubic-bezier(.47,0,.74,.71);
}

.navbar-show li a:hover {
    color: #fff;
    background: linear-gradient(94.06deg, #034A87 10.32%, #481C69 82.09%);
    clip-path: polygon(0% 100%, 1000% 100%, 100% 0%, 0% 0%);
}

.navbar-show .menu li a:hover {
    color: #fff;
}

.navbar-link {
    text-decoration: none;
    padding: 10px;
    display: block;
    color: #000;
    font-size: 1vw;
    font-weight: 600;
    margin-left: 1.5vw;
    transition: color 0.3s ease;
}

a {
    text-decoration: none;
}

.contactPlusNav{
    width: 18.6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 73vw;
    height: 3vw;
}

.contactPlusNav .button-container button {
    align-items: center;
    display: flex;
    font-size: .6vw;
    height: 2.8vw;
    justify-content: center;
    top: 0;
    width: 9vw;
}

.contactPlusNav .button-container button h1{
    margin: 0;
    padding: 0;
    font-weight: 200;
}

.contactPlusNav .button-container{
    display: flex;
    width: 100%;
    z-index: 999;
}

.openNav .button-container{
    z-index: 0;
}

.navbar-show .right ul{
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.navbar-show .right li:nth-child(1) a {
    height: calc(100% - 3vw);
    margin: 0;
    padding-left: 14vw;
    padding-top: 3vw;
    width: 74%;
}

.closePlusLink li{
    height: 100%;
    position: static;
    text-align: start;
    width: 88%;
}

.openNav .navbar-toggle {
    display: none;
}

.navbar-show .right li:nth-child(2) a{
    padding-left: 24vw;
    padding-top: 2.8vw;
    height: calc(100% - 2.8vw);
    width: 100%;
}

.navbar-show .right li:nth-child(3) a{
    padding-left: 34vw;
    padding-top: 3vw;
    height: calc(100% - 3vw);
    width: 100%;
}

.navbar-show .right li:nth-child(4) a{
    padding-left: 44vw;
    padding-top: 3vw;
    height: calc(100% - 3vw);
    width: 100%;
}

.navbar-show .border {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #fff;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
}

.navbar-show .left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #000;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
    border-right: 0vw solid black;
}

.navbar-show .right{
    position: absolute;
    top: 0vw;
    width: 100%;
    height: 100%;
    background-color: #fff;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 100%);
    transition: clip-path 2s cubic-bezier(.45,.05,.55,.95);
}

.navbar-show .navBottomRightHeading {
    background: linear-gradient(90.2deg, #0a4483 .54%, #421f6b 101.49%);
    background-clip: border-box;
    background-clip: text;
    color: #000;
    font-family: Josefin Sans;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 700;
    left: 83vw;
    line-height: 128px;
    margin: 0;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 90vh;
    height: 3vw;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}

.navbar-show .background {
    width: 100%;
    position: absolute;
    left: 0;
    height: 100vh;
}
.openNav{
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 21vw;
    height: 20vh;
    top: 0;
}

.navbar-hide .left {
    width: 0;
}

.navbar-hide .right{
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    height: 0;
}

.navbar-hide li a {
    position: absolute;
    top: -8vw;
    opacity: 0;
    letter-spacing: 0;
}

.navbar-hide .border {
    display: none;
}


.x-show .xSvg {
    height: 6vw;
    width: 6vw;
}


.xSVGhidden{
    display: none;
}

.x-show:hover > .xSvg path{
    fill: white;
}

.closePlusLink{
    border-bottom: 1px solid #000;
    display: flex;
    height: 20%;
    list-style: none;
    position: relative;
    text-align: start;
    transition: border-bottom 1s ease;
    width: 100%;
}

@media (max-width: 850px) {

    .navbar .logo {
        width: 60vw;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .navbar-width {
        align-items: center;
        background-color: #fff;
        box-shadow: 0 -5vw 7vw 0 gray;
        display: flex;
        height: 8vh;
        justify-content: space-between;
        max-width: 100%;
        padding: 0 7vw;
    }

    .navbar .logo a{
        color: #000;
        font-family: Josefin Sans;
        font-size: 3vw;
        font-style: normal;
        font-weight: 700;
        line-height: 128px;
        margin-left: 0;
        text-align: center;
        text-decoration: none;
    }
    .contactPlusNav {
        align-items: center;
        display: flex;
        justify-content: space-between;
        left: 54vw;
        position: absolute;
        width: 45.6%;
        height: 100%;
    }

    .contactPlusNav .button-container {
        display: flex;
        width: 70%;
        z-index: 999;
    }

    .contactPlusNav .button-container button{
        align-items: center;
        border-radius: 4vw;
        display: flex;
        font-size: 1.8vw;
        height: 7.8vw;
        justify-content: center;
        width: 24vw;
        position: initial;
    }

    .contactPlusNav .button-container button h1 {
        font-weight: 200;
        margin: 0;
        padding: 0;
        padding-top: 0px;
        padding-top: 0.5vw;
    }

    .navbar-toggle{
        background-color: initial;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 4.5vw;
        justify-content: space-between;
        left: 33.55vw;
        padding: 0;
        position: absolute;
        top: 6.2vw;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
        width: 5.5vw;
        z-index: 999;
    }

    .navbar-toggle:hover{
        transform: translateY(0vw);
    }

    .hamburger{
        height: 0.9vw;
    }

    .navbar-hide {
        transition: height 1s ease, background 1s ease;
        height: 0;
        left: 0;
        margin: 0;
        margin-top: 0px;
        position: fixed;
        text-align: center;
        top: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background: white;
        z-index: 999;
        margin-top: 8vh;
        opacity: 1;
    }

    .left, .border, .navBottomRightHeading{
        display: none;
    }

    .navbar-hide li {
        border-bottom: 0 solid transparent;
    }

    .navbar-hide ul:first-child{
        border-top: 0 solid transparent;
    }

    .navbar-hide li a {
        letter-spacing: 0;
        opacity: 0;
        position: absolute;
        top: -8vw;
    }

    .navbar-show {
        height: 100vh;
        left: 0;
        margin: 0;
        position: fixed;
        text-align: center;
        top: 0;
        transition: height 2s ease;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background: linear-gradient(180deg, #044986 2.52%, #481c69 86.55%);
        opacity: 1;
        padding: 0;
    }

    .xSVGhidden {
        opacity: 0;
        height: 0;
        width: 0;
        display: block;
    }

    .xSvg path{
        fill: white
    }

    .navbar-show .right {
        height: 89%;
        width: 100%;
        background-color: inherit;
        transition: clip-path 2s cubic-bezier(.45, .05, .55, .95), -webkit-clip-path 2s cubic-bezier(.45, .05, .55, .95);
        clip-path: none;
        position: initial;
    }

    .navbar-show .right ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 83vh;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .navbar-show ul:first-child{
        border-top: 1px solid white;
        transition: border-top 2s cubic-bezier(.95, .05, .8, .04);
    }


    .navbar-show li {
        border-bottom: 1px solid #fff;
        display: flex;
        list-style: none;
        position: relative;
        text-align: center;
        transition: border-bottom 2s cubic-bezier(.95, .05, .8, .04);
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .navbar-show li a {
        color: #fff;
        display: flex;
        font-family: Josefin Sans;
        font-size: 11vw;
        font-weight: 600;
        letter-spacing: 3vw;
        opacity: 1;
        padding: 0;
        transition: color .3s ease;
        transition: top 1s ease, opacity .5s ease, letter-spacing 2s cubic-bezier(.47, 0, .74, .71);
        justify-content: center;
        align-items: center;
        height: 0;
        position: initial;
    }

    .navbar-show .right li:first-child a {
        height: calc(100% - 3vw);
        margin: 0;
        width: 100%;
        padding: 0;
    }

    .navbar-show .right li:nth-child(2) a {
        height: calc(100% - 3vw);
        width: 100%;
        padding: 0;
    }

    .navbar-show .right li:nth-child(3) a {
        height: calc(100% - 3vw);
        width: 100%;
        padding: 0;
    }

    .navbar-show .right li:nth-child(4) a {
        height: calc(100% - 3vw);
        width: 100%;
        padding: 0;
    }


    .openNav {
        align-items: center;
        display: flex;
        justify-content: space-between;
        left: 0;
        position: absolute;
        width: 100%;
        top: 0;
        height: 136%;
    }

    .x-show {
        align-items: center;
        display: flex;
        left: 0;
        position: absolute;
        top: -14vh;
        width: 100%;
        background-color: initial;
        border: none;
        cursor: pointer;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0;
        z-index: 999;
    }

    .x-show .xSvg {
        height: 7vw;
        transition: opacity 2s cubic-bezier(.6, .04, .98, .34);
        width: 7vw;
        position: initial;
    }

    .navbar-show .navBottomRightHeading{
        display: none;
    }

    .x-show:hover {
        background: transparent;
    }

    .closePlusLink {
        display: flex;
        height: 20%;
        list-style: none;
        position: relative;
        text-align: start;
        width: 100%;
        border: 0;
    }

    .openNav .button-container {
        z-index: 0;
        display: none;
    }


}

