.ContactUsPage-section {
    display: flex;
    color: #000000;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 500px;
}

.ContactUsPage-container {
    margin: 0;
    width: 100%;
    min-height: 500px;
}

.ContactUsPage-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;
}

.container {
    position: relative;
    width: 100%;
    min-height: 500px;
}

.gradient-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 81vh;
    width: 100%;
    background: linear-gradient(180deg, #044986 2.52%, #481C69 86.55%);
    flex-direction: column;
}

.gradient-bg h1 {
    color: #fff;
    font-family: Josefin sans;
    font-size: 7vw;
    text-align: center;
    margin: 0;
    margin-bottom: 0px;
    margin-bottom: 6vw;
}

.white-bg {
    width: 100%;
    height: 128vh;
    background: #ffffff;
}

.form-wrapper {
    position: absolute;
    top: 130vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 87vw;
    height: 77vw;
    background: #ffffff;
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    box-shadow: 0px 0px 17px -4px gray;
}

/* Additional styling for the form elements */
.form {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.contact-us-form-heading {
    font-size: 3vw;
    color: #3D3B3B;
    font-weight: 200;
    font-family: 'Josefin Sans';
    width: 83%;
    text-align: center;
    top: 16vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 8vw;
}

.contact-info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 45vh;
    width: 38%;
}

.email-info{
    display: flex;
    justify-content: space-between;
    height: fit-content;
    width: 44%;
    align-items: center;
}

.contact-info .icon {
    display: flex;
    width: 1.4vw;
    height: 1.4vw;
    background: transparent;
    border-radius: 130px;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.phone-info{
    display: flex;
    justify-content: space-between;
    width: 34%;
    height: fit-content;
    align-items: center;
}

.contact-info h1 {
    font-size: 1.3vw;
    color: rgb(255, 255, 255);
    font-weight: 200;
    font-family: "Josefin Sans";
    text-align: center;
    margin: 0;
}

.form input {
    height: 4vw;
    width: 32vw;
    border-radius: .5vw;
    font-size: 1.5vw;
    font-family: 'Josefin Sans';
    font-style: normal;
    padding-left: 2vw;
    border: 0;
    border: 1px solid black;
}

.top-part{
    width: 73.5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20vh;
    height: 7vw;
}

.bot-part{
    width: 73.5vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7vw;
}

.form button {
    position: absolute;
    width: 20vw;
    top: 87.5%;
    font-size: 1vw;
    height: 4vw;
    background: linear-gradient(94.06deg, #034A87 10.32%, #481C69 82.09%);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 3vw;
    COLOR: white;
    font-size: 1.3vw;
    font-family: 'Josefin Sans';
    padding-top: 8px;
    transition: all 400ms ease-in;
    border: 0;
}

.form button:hover{
    transform: translateY(-.2vw);
}

textarea {
    width: 71.3vw;
    height: 19vw;
    margin-top: 2vw;
    border-radius: .5vw;
    resize: vertical;
    font-size: 1.5vw;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    color: black;
    padding-left: 2vw;
    padding-top: 1.5vw;
    border: 1px solid black;
}

textarea::placeholder{
    font-size: 1.5vw;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    color: black;
}

input::placeholder{
    font-size: 1.5vw;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    color: black;
}


@media (max-width: 850px) {
    .ContactUsPage-section {
        align-items: center;
        background-color: #fff;
        color: #000;
        display: flex;
        justify-content: center;
        min-height: 500px;
        width: 100%;
    }

    .gradient-bg {
        align-items: center;
        background: linear-gradient(180deg, #044986 2.52%, #481c69 86.55%);
        display: flex;
        flex-direction: column;
        height: 76vw;
        justify-content: center;
    }

    .gradient-bg h1 {
        color: #fff;
        font-family: Josefin sans;
        font-size: 7vw;
        margin: 0 0 6vw;
        text-align: center;
    }

    .contact-info {
        flex-direction: row;
        justify-content: space-between;
        top: 41vw;
        width: 44%;
    }

    .email-info {
        align-items: center;
        display: flex;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        justify-content: space-between;
        width: 47%;
    }

    .phone-info {
        align-items: center;
        display: flex;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        justify-content: space-between;
        width: 37%;
    }

    .contact-info h1 {
        color: #fff;
        font-family: Josefin Sans;
        font-size: 1.6vw;
        font-weight: 200;
        margin: 0;
        text-align: center;
    }

    .contact-info .icon {
        align-items: center;
        background: transparent;
        border-radius: 130px;
        display: flex;
        height: 2vw;
        justify-content: center;
        margin: 0;
        width: 2vw;
    }

    .white-bg {
        background: #fff;
        height: 55vw;
        width: 100%;
    }

    .form,
    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .form-wrapper {
        align-content: center;
        background: #fff;
        border-radius: 2vw;
        box-shadow: 0 0 17px -4px gray;
        left: 50vw;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 87vw;
        height: 72vw;
        top: 85vw;
    }

    .contact-us-form-heading {
        color: #3d3b3b;
        font-family: Josefin Sans;
        font-size: 3vw;
        font-weight: 200;
        justify-content: center;
        left: 8vw;
        text-align: center;
        top: 8vw;
        width: 83%;
    }

    .top-part {
        margin-top: 11vw;
    }

    .bot-part,
    .top-part {
        align-items: center;
        display: flex;
        height: 7vw;
        justify-content: space-between;
        width: 73.5vw;
    }

    .App-header{
        background-color: #fff;
        font-size: calc(10px + 2vmin);
        min-height: 0;
    }

    .form button {
        COLOR: #fff;
        background: linear-gradient(94.06deg, #034a87 10.32%, #481c69 82.09%);
        border: 0;
        border-radius: 3vw;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, .25);
        font-family: Josefin Sans;
        font-size: 1vw;
        font-size: 1.5vw;
        height: 6vw;
        padding-top: .5vw;
        position: absolute;
        top: 87.5%;
        transition: all .4s ease-in;
        width: 22vw;
    }
}