.aboutUs-section {
    display: flex;
    color: #000000;
    min-height: 500px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.aboutUs-container {
    margin: 0;
    display: flex;
}

.aboutUs-content {
    align-items: center;
    display: flex;
    height: 69vh;
    justify-content: space-between;
    padding: 0 8vw;
    position: relative;
    width: 100vw;
}

.aboutUs-content .column-left{
    width: 50%;
    width: 50%;
    padding: 0vw 0vw 0vw 8vw;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
}

.aboutUs-content .column-right {
    width: 70%;
}

.aboutUs-section h1 {
    font-family: 'JOSEFIN SANS';
    font-size: 3.6vw;
    width: 37vw;
    text-align: left;
    color: #3D3B3B;
    line-height: 4.5vw;
}

.aboutUs-section p {
    font-family: 'Josefin Sans';
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    width: 36vw;
    text-align: left;
    padding-bottom: 5vw;
    line-height: 2vw;
}

.aboutUs-section button {
    height: 4vw;
    width: 13vw;
    top: -2vh;
}

.aboutUs-content .button-container {
    display: flex;
    justify-content: flex-start;
}

.aboutUs-section img {
    width: 40vw;
}

@media (max-width: 850px) {
    .aboutUs-section{

        background-color: #fff;
        color: #000;
        display: flex;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        justify-content: center;
        margin-top: 11vw;
        min-height: 500px;
        width: 100%;
    }

    .aboutUs-content {
        align-items: center;
        display: flex;
        height: 175vw;
        justify-content: space-between;
        position: relative;
        width: 100vw;
        flex-direction: column;
    }

    .aboutUs-container {
        margin: 0;
        height: 100%;
        display: flex;
    }

    .aboutUs-content .column-left {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 50%;
        justify-content: space-evenly;
        padding: 0;
        width: 100%;
    }

    .aboutUs-section h1 {
        color: #3d3b3b;
        font-family: JOSEFIN SANS;
        font-size: 7.6vw;
        line-height: 9.5vw;
        text-align: left;
        width: 86vw;
        font-weight: 1000;
        margin: 0;
    }

    .aboutUs-section p {
        font-family: Josefin Sans;
        font-size: 3.7vw;
        font-style: normal;
        font-weight: 400;
        line-height: 6vw;
        padding-bottom: 5vw;
        text-align: left;
        width: 86vw;
        margin: 0;
    }

    .aboutUs-section button {
        border-radius: 12vw;
        font-size: 3vw;
        height: 10vw;
        padding-top: .5vw;
        width: 29vw;
        top: 0;
    }

    .aboutUs-content .column-right {
        width: 100%;
        height: 50%;
    }

    .aboutUs-content .column-right .figure{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .aboutUs-section img {
        width: 84vw;
    }
}
