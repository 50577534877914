.footer-section {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3vw;
}

.footer-section h5 {
    position: relative;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: .7vw;
    line-height: 124.5%;
    color: #000000;
}

@media (max-width: 850px) {
    .footer-section {
        align-items: center;
        display: flex;
        height: 7vw;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .footer-section h5 {
        color: #000;
        font-family: Josefin Sans;
        font-size: 2.7vw;
        font-style: normal;
        font-weight: 400;
        line-height: 124.5%;
        position: relative;
    }
}