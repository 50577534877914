.App {
    text-align: center;
}

.App-header {
    background-color: #fff;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
}

.servicesPage-Navbar {
    display: flex;
    align-items: center;
    max-width: 100%;
    justify-content: space-between;
    padding: 0vw 8vw 0vw 8vw;
    background-color: white;
    z-index: 999;
}

.servicesPage-Navbar .logo {
    padding-left: 0vw;
}

.servicesPage-section {
    display: flex;
    color: #000000;
    min-height: 500px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.servicesPage-section2 {
    display: flex;
    color: #000000;
    min-height: 500px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.servicesPage-container {
    height: 100vh;
    margin: 0;
    width: 100%;
    min-height: 500px;
}

.servicesPage-container2 {
    margin: 0;
    width: 100%;
}

.servicesPage-content1 {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    min-height: 500px;
    background: linear-gradient(180deg, #034A87 10.32%, #481C69 82.09%);
}

.servicesPage-content1 .left-column {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-left: 8%;
    height: 50%;
}

.servicesPage-content1 .left-column .heading {
    width: 100%;
}

.servicesPage-content1 .left-column .content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
}

.servicesPage-content1 .left-column h1{
    font-size: 6.2vw;
    color: white;
    font-weight: 1000;
    font-family: 'Josefin Sans';
    width: 100%;
    text-align: start;
    margin: 0;
}

.servicesPage-content1 .left-column p {
    color: white;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    line-height: 164%;
    text-align: start;
    width: 95%;
    margin: 0;
}

.servicesPage-content1 .left-column #services-one {
    margin: 3vw 0vw 2vw 0vw;
}




.servicesPage-content1 .right-column {
    width: 80%;
    height: 76%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.servicesPage-content1 .right-column img {
    width: 30vw;
}


.servicesPage-content2 .services .services-list{
    list-style: none;
    width: 83.6vw;
    padding: 0vw 0vw 0vw 8vw;
}

.servicesPage-content2 .services .services-list .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}

.servicesPage-content2 .services .services-list .list-item::after {
    position: absolute;
    content: '';
    height: 0.09vw;
    bottom: 6px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    background: black;
}

.servicesPage-content2 .services .services-list .list-item {
    display: flex;
    color: #3D3B3B;
    justify-content: space-between;
    align-items: center;
    position: relative;
}


.servicesPage-content2 .services .services-list h1 {
    margin-bottom: 0;
    font-size: 6vw;
    font-family: 'Josefin Sans';
}

.servicesPage-content2 .services .services-list .list-item .arrow-button {
    display: inline;
    width: 5.5vw;
    height: 5.5vw;
    cursor: pointer;
    transition: all 400ms ease;
    margin-top: 3.4vw;
    z-index: 998;
}

.servicesPage-content2 .services .services-list .list-item .rotate-arrow {
    transform: rotate(-90deg);
}

.servicesPage-content2 .services .services-list .list-item svg {
    width: 5.5vw;
    height: 5.5vw;
}

.fade-in-paragraph {
    opacity: 0;
    transition: opacity 100ms ease, margin 200ms ease;
    text-align: left;
    font-size: 1.2vw;
    margin: -3vw 0px 2vw 0px;
}

.fade-in-paragraph.show {
    opacity: 1;
    margin: 4vw 0vw 4vw 0px;
}

.special-paragraph {
    margin: -3vw 0px -13vw 0px;
}

.special-paragraph .paragraph-list {
    list-style: none;
}

/*taken from https://www.w3schools.com/howto/howto_css_bullet_color.asp*/

.special-paragraph .paragraph-list li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: rgb(0, 0, 0); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

@media (max-width: 850px) {
    .servicesPage-section{
        align-items: center;
        background-color: #fff;
        color: #000;
        display: flex;
        justify-content: center;
        min-height: 420px;
        width: 100%;
    }

    .servicesPage-container {
        width: 100%;
        justify-content: center;
        align-items: end;
        background: linear-gradient(180deg, #034a87 10.32%, #481c69 82.09%);
        display: flex;
        height: 100vh;
        margin: 0;
        min-height: 500px;
        padding: 0;
    }

    .servicesPage-content1 {
        flex-wrap: nowrap;
        min-height: 500px;
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 92vh;
        justify-content: space-evenly;
        width: 100vw;
    }


    .servicesPage-content1 .left-column {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 40%;
        justify-content: space-evenly;
        padding: 0;
        width: 100%;
    }

    .servicesPage-content1 .left-column .heading {
        width: 84%;
    }

    .servicesPage-content1 .left-column h1 {
        color: #fff;
        font-family: Josefin Sans;
        font-size: 3em;
        font-weight: 1000;
        margin: 0;
        text-align: start;
        width: 100%;
    }

    .servicesPage-content1 .left-column .content {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 84%;
    }

    .servicesPage-content1 .left-column #services-one {
        margin: 3vw 0 2vw;
    }

    .servicesPage-content1 .left-column p {
        font-weight: 400;
        width: 95%;
        color: #fff;
        font-family: Josefin Sans;
        font-size: .7em;
        font-style: normal;
        line-height: 164%;
        text-align: start;
    }

    .servicesPage-content1 .right-column {
        align-items: flex-end;
        display: flex;
        height: 45%;
        justify-content: center;
        width: 100%;
    }

    .servicesPage-content1 .right-column .figure{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .servicesPage-content1 .right-column img {
        width: 71vw;
        height: fit-content;
    }

    .servicesPage-content2 .services .services-list h1 {
        font-family: Josefin Sans;
        font-size: 7vw;
        margin-bottom: 0;
        margin-top: 5vw;
    }

    .servicesPage-content2 .services .services-list .list-item::after {
        background: #000;
        bottom: 6px;
        content: "";
        height: 0.3vw;
        left: 0;
        margin: 0vw auto;
        position: absolute;
        right: 0;
        width: 100%;
    }

    .fade-in-paragraph {
        font-size: 1.2vw;
        margin: -3vw 0 2vw;
        opacity: 0;
        text-align: left;
        transition: opacity .1s ease, margin .2s ease;
    }

    .fade-in-paragraph.show {
        margin: 4vw 0;
        margin-bottom: 4vw;
        opacity: 1;
        font-size: 3vw;
        margin-bottom: 8vw;
    }

    .pBtm{
        margin-bottom: 0;
    }

    .special-paragraph {
        margin: -3vw 0 -12vw;
    }
}