.services-section {
    align-items: center;
    background-color: #fff;
    color: #000;
    display: flex;
    height: fit-content;
    justify-content: center;
    min-height: 500px;
    width: 100%;
    margin-bottom: 5vw;
}

.services-container {
    display: flex;
    margin: 0;
    min-height: 500px;
    padding: 0;
    height: fit-content;
}

.services-content {
    align-items: center;
    display: flex;
    height: fit-content;
    justify-content: space-between;
    position: relative;
    width: 100vw;
}

.services-content .left-column {
    width: 45%;
    height: 93vh;
    position: relative;
}

.services-content .right-column {
    height: fit-content;
    width: 50%;
}

.services-content .figure img {
    height: 40vw;
    width: 35vw;
}

.main-heading {
    font-family: 'JOSEFIN SANS';
    font-size: 3.6vw;
    width: 40vw;
    text-align: left;
    padding: 0vw 1vw;
    color: #3D3B3B;
    line-height: 4.5vw;
}

.services-content .services {
    width: 61%;
    height: 50%;
}

.services-content .services ul {
    list-style: none;
    padding: 0 0 0 1vw;
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.services-content .services li {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    height: fit-content;
    position: cursor;
}

.services-content .services li::after {
    position: absolute;
    content: '';
    height: .1vw;
    bottom: .8vw;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    background: black;
}

.services-content .services li h1 {
    display: inline;
    font-size: 1.1vw;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 600;
    line-height: 124.5%;
    color: #3D3B3B;
    margin: 0;
    margin-top: 1vw;
}

.services-content .services li .arrow-button {
    display: inline;
    width: 2vw;
    height: 2vw;
    cursor: pointer;
    transition: all 1s ease;
    margin-top: 1vw;
}

.right-column .fade-in-paragraph.dmPara {
    margin: -1.6vw 0 1vw;
}

.dmPara.show{
    margin: 0.4vw 0 1.5vw;
    transition: opacity 2s cubic-bezier(.47, 0, .74, .71), margin 1s linear;
}

.services-content .services li .rotate-arrow {
    
    transform: rotate(-90deg);
}

.services-content .services li svg {
    width: 2vw;
    height: 2vw;
    top: 1.1vw;
    left: 37vw;
}

.right-column .fade-in-paragraph {
    font-size: 1vw;
    margin: -1.6vw 0 0vw;
    opacity: 0;
    text-align: left;
    transition: opacity 500ms ease, margin 1s ease;
}

.right-column .fade-in-paragraph.show {
    margin: 1vw 0 2.5vw;
    opacity: 1;
    transition: opacity 2s cubic-bezier(.47, 0, .74, .71), margin 1s linear;
}

@media (max-width: 850px) {
    .services-section{
        align-items: center;
        background-color: #fff;
        color: #000;
        display: flex;
        height: fit-content;
        justify-content: center;
        margin-bottom: 10vw;
        margin-top: 11vw;
        min-height: 420px;
        width: 100%;
    }

    .services-container {
        display: flex;
        margin: 0;
        min-height: 500px;
        padding: 0;
        height: fit-content;
    }

    .services-content {
        align-items: center;
        display: flex;
        height: fit-content;
        justify-content: space-evenly;
        width: 100vw;
        flex-direction: column-reverse;
    }

    .services-content .left-column {
        height: 50%;
        width: 100%;
    }

    .services-content .left-column .figure{
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    .services-content .figure img {
        height: 87vw;
        width: 74vw;
    }

    .services-content .right-column {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: fit-content;
        justify-content: center;
        width: 100%;
        margin-bottom: 4vw;
    }

    .main-heading {
        color: #3d3b3b;
        font-family: JOSEFIN SANS;
        font-size: 7.6vw;
        line-height: 9.5vw;
        margin: 0;
        padding: 0;
        text-align: left;
        width: 86%;
    }

    .services-content .services {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
        margin-top: 8vw;
    }

    .services-content .services ul {
        list-style: none;
        padding: 0;
        width: 87vw;
        height: 100%;
    }

    .services-content .services li::after {
        background: #000;
        bottom: 2vw;
        content: "";
        height: 0.3vw;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        width: 100%;
    }

    .services-content .services li {
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        flex-direction: column;
        cursor: pointer;
    }

    .services-content .services li h1 {
        color: #3d3b3b;
        display: inline;
        font-family: Josefin Sans;
        font-size: 3.1vw;
        font-style: normal;
        font-weight: 600;
        line-height: 124.5%;
        margin: revert;
        margin-top: 4vw;
    }

    .services-content .services li .arrow-button {
        cursor: pointer;
        display: inline;
        height: 6vw;
        transition: all 1s ease;
        left: 82vw;
        top: 1.1vw;
        width: 6vw;
        position: absolute;
    }

    .services-content .services li svg {
        height: 6vw;
        width: 6vw;
    }

    .right-column .fade-in-paragraph {
        font-size: 3vw;
        opacity: 0;
        text-align: left;
        transition: opacity 500ms linear, margin 1s linear;
        margin: -1.6vw 0 -7vw;
    }

    .right-column .fade-in-paragraph.show {
        margin: -.5vw 0 6vw;
        opacity: 1;
        transition: opacity 2s cubic-bezier(.47, 0, .74, .71), margin 1s linear;
    }

    .right-column .fade-in-paragraph.bPara {
        margin: -1.6vw 0 -11vw;
    }

    
    .right-column .fade-in-paragraph.bPara.show {
        margin: -0.5vw 0 6vw;
    }

    .right-column .fade-in-paragraph.dmPara.show {
        margin: -0.5vw 0 6vw;
    }


    .right-column .fade-in-paragraph.dmPara {
        margin: -1.6vw 0 -7vw;
    }
}
