.conclusion-section {
    background-color: #fff;
    color: #000;
    height: 100vh;
}

.conclusion-container {
    background: linear-gradient(180deg, #034a87 10.32%, #481c69 82.09%);
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.conclusion-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 90vh;
    justify-content: space-evenly;
    width: 100%;
}

.conclusion-content p {
    color: white;
    font-family: 'Josefin Sans';
    width: 84%;
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.35vw;
    line-height: 164%;
}

.conclusion-content button {
    background: #fff;
    border-radius: 3vw;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .25);
    color: #000;
    font-family: Josefin Sans;
    font-size: 1.3vw;
    height: 4vw;
    transition: all .4s ease-in;
    width: 13vw;
    top: 0;
}

.conclusion-content button h1 {
    color: #000;
    font-family: Josefin Sans;
    margin: 0;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #044986 2.52%, #481c69 86.55%);
    background-clip: border-box;
    background-clip: text;
    font-size: 1.2vw;
    font-weight: 200;
    letter-spacing: .05vw;
}

.conclusion-content .BrandName {
    font-size: 1.2vw;
    color: white;
    font-weight: 500;
    font-family: 'Josefin Sans';
    margin-top: 2vw;
}

.conclusion-container h2 {
    font-size: 1.2vw;
    color: white;
    font-weight: 500;
    font-family: 'Josefin Sans';
    margin-top: 2vw;
}

.conclusionHeadings{
    height: fit-content;
    width: fit-content; 
}

@media (max-width: 850px) {
    .conclusion-section {
        background-color: #fff;
        color: #000;
        height: 100vw;
    }

    .conclusion-container {
        background: linear-gradient(180deg, #034a87 10.32%, #481c69 82.09%);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .conclusion-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        height: 100vw;
        justify-content: space-evenly;
        position: relative;
        width: 100%;
    }

    .conclusion-content p {
        color: #fff;
        font-family: Josefin Sans;
        font-size: 3vw;
        font-style: normal;
        font-weight: 400;
        line-height: 164%;
        width: 86%;
        text-align: left;
    }

    .conclusion-content button {
        COLOR: #000;
        align-items: center;
        background: #fff;
        border-radius: 8vw;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, .25);
        display: flex;
        font-family: Josefin Sans;
        font-size: 1.3vw;
        height: 10vw;
        justify-content: center;
        transition: all .4s ease-in;
        width: 29vw;
        top: 0;
    }

    .conclusion-content button h1 {
        color: #000;
        font-family: Josefin Sans;
        margin: 0;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, #044986 2.52%, #481c69 86.55%);
        background-clip: border-box;
        background-clip: text;
        font-size: 3vw;
        font-weight: 200;
        letter-spacing: .05vw;
        padding-top: .5vw;
    }

    .conclusion-container h2,
    .conclusion-content .BrandName {
        color: #fff;
        font-family: Josefin Sans;
        font-size: 3.2vw;
        font-weight: 500;
    }

    .conclusionHeadings {
        height: fit-content;
        width: fit-content;
    }
}